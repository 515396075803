import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import ResourceCard from '../../components/resource/ResourceCard';
import Button, { ButtonType } from '../../components/shared/form-control/Button';
import DropdownSelect from '../../components/shared/form-control/DropdownSelect';
import { SearchInput } from '../../components/shared/form-control/SearchInput';
import PlusIcon from '../../components/shared/icon/PlusIcon';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import useDebounce from '../../hooks/useDebounce';
import { FormConfig } from '../../models/Form';
import { currentClientAtom, currentTenantIdAtom } from '../../recoil/atoms/Clients';
import { Option } from '../../components/Option';
import TemplateFormService from '../../services/TemplateFormService';
import { FormType } from '../../models/FormTypes';
import InfoIcon from '../../components/shared/icon/InfoIcon';
import { Roles } from '../../models/Role';
import ClientTemplateFormService from '../../services/ClientTemplateFormService';
import { nextTick } from '../../utils/ReactUtils';
import Loader from '../../components/shared/Loader';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import usePermissions from '../../hooks/permissions/usePermissions';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../../components/shared/modal/variants/StandardModal';
import LanguageUtils from '../../utils/LanguageUtils';

const Resources: FC = () => {
  const { t } = useTranslation('resources');
  const [loading, setLoading] = useState(false);
  const pageContentRef = useRef<HTMLDivElement>(null);
  const [resources, setResources] = useState<FormConfig[]>([]);
  const [globalResourceTemplates, setGlobalResourceTemplates] = useState<Option<string, string>[]>([]);
  const [selectedGlobalResource, setSelectedGlobalResource] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [resourceSearchPhrase, setResourceSearchPhrase] = useState('');
  const debouncedSearchTerm = useDebounce(resourceSearchPhrase, 500);
  const currentClient = useRecoilValue(currentClientAtom);
  const currentTenantId = useRecoilValue(currentTenantIdAtom);
  const [showImportModal, setShowImportModal] = useState(false);
  const navigate = useNavigate();
  const [lastElementRef] = useInfiniteScroll(currentPage < totalPages ? () => setCurrentPage(currentPage + 1) : null, loading);
  const hasPermission = usePermissions();

  const canMaintainResources = useMemo(() => hasPermission(Roles.TeamLead), [hasPermission]);

  const clientTemplateFormService = useMemo(() => {
    if (currentClient) {
      return new ClientTemplateFormService(currentClient.id);
    }
  }, [currentClient]);

  useEffect(
    function loadGlobalResourceTemplates() {
      TemplateFormService.getAllTemplates({ latestOnly: true, types: [FormType.Resource] }).then((res) => {
        setGlobalResourceTemplates(
          res.data
            .filter((globalResource) => !resources.find((x) => x.id === globalResource.id))
            .map((resource) => ({
              id: resource.id,
              value: resource.id,
              text: LanguageUtils.getTranslation('title', resource.translations),
            })),
        );
      });
    },
    [resources],
  );

  useEffect(
    function loadResources() {
      setLoading(true);
      const scrollTop = pageContentRef.current?.scrollTop || 0;
      clientTemplateFormService
        ?.getAllTemplatesPaged({
          latestOnly: true,
          searchTerm: debouncedSearchTerm,
          pageNumber: currentPage,
          pageSize: 15,
          types: [FormType.Resource],
        })
        .then((res) => {
          setTotalPages(res.totalPages || 0);
          setResources((prev) => {
            if (prev && currentPage > 1) {
              return [...prev, ...res.data];
            }
            return res.data;
          });
          setLoading(false);
          nextTick(() => {
            pageContentRef.current?.scrollTo({ top: scrollTop });
          });
        });
    },
    [clientTemplateFormService, currentPage, debouncedSearchTerm],
  );

  const importGlobalResource = useCallback(() => {
    const selected = globalResourceTemplates.find((x) => x.id === selectedGlobalResource);
    if (selected && clientTemplateFormService) {
      clientTemplateFormService.importTemplate(selected?.id).then((res) => {
        setResources((prev) => [...prev, { ...res.data }]);
        setShowImportModal(false);
      });
    }
  }, [clientTemplateFormService, globalResourceTemplates, selectedGlobalResource]);

  const onSearch = useCallback((value: string) => {
    setLoading(true);
    setResources([]);
    setCurrentPage(1);
    setResourceSearchPhrase(value);
  }, []);

  const onImportModalClose = useCallback(() => {
    setSelectedGlobalResource('');
    setShowImportModal(false);
  }, []);

  return (
    <>
      <div className="bg-background-1 flex min-h-full flex-col p-6">
        <div className="flex justify-end">
          <TopNavPortal>
            <Heading size={HeadingSize.H2} actualSize={HeadingSize.H3}>
              {t('heading')}
            </Heading>
          </TopNavPortal>
          <div className="flex justify-between gap-4">
            <div className="w-80">
              <SearchInput
                placeholder={t('search')}
                value={resourceSearchPhrase}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
                data-cy="search-resource"
              />
            </div>
            {canMaintainResources && (
              <>
                <Button onClick={() => setShowImportModal(true)} type={ButtonType.SECONDARY} data-cy="import-resource">
                  {t('buttons.import')}
                </Button>
                <Button
                  onClick={() => navigate(`/clients/${currentClient?.id}/resource-builder`)}
                  type={ButtonType.PRIMARY}
                  data-cy="create-new-resource"
                >
                  <Button.Slot name="Icon">
                    <PlusIcon className="w-4" />
                  </Button.Slot>
                  {t('buttons.create')}
                </Button>
              </>
            )}
          </div>
        </div>
        {loading && (
          <div className="flex flex-col items-center py-6">
            <Loader centered />
          </div>
        )}
        {!!resources.length && !loading && (
          <div className="mt-8 flex w-full flex-wrap gap-4">
            {resources.map((resource, i) => {
              const isLast = resources.length === i + 1;
              return (
                <div key={resource.id} ref={isLast ? lastElementRef : undefined}>
                  <ResourceCard data={resource} />
                </div>
              );
            })}
          </div>
        )}
        {!resources.length && !loading && (
          <div className="flex h-full w-full flex-grow items-center">
            <div data-cy="resource-template-list-empty" className="flex h-full w-full items-center justify-center">
              <div className="text-center">
                <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
                <Heading size={HeadingSize.H3} className="my-4">
                  {t('template-list.empty.heading')}
                </Heading>
                <div className="text-dpm-20 pb-5">
                  {t(canMaintainResources ? 'template-list.empty.sub-heading' : 'template-list.empty.sub-heading-no-permission')}
                </div>
                {canMaintainResources && (
                  <Button
                    onClick={() =>
                      currentClient
                        ? navigate(`/clients/${currentClient.id}/resource-builder`)
                        : navigate(`/builder/${currentTenantId}/resource-builder`)
                    }
                    type={ButtonType.PRIMARY}
                    data-cy="create-new-resource"
                  >
                    <Button.Slot name="Icon">
                      <PlusIcon className="w-4" />
                    </Button.Slot>
                    {t('buttons.create')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <ModalContext.Provider value={{ open: showImportModal, onClose: () => onImportModalClose(), modalWidth: 'w-2/5' }}>
        <StandardModal
          title={t('modals.import.heading')}
          cancelButtonTitle={t('modals.import.buttons.cancel')}
          confirmButtonTitle={t('modals.import.buttons.confirm')}
          onCancelClick={() => onImportModalClose()}
          onConfirmClick={importGlobalResource}
          confirmDisabled={!selectedGlobalResource}
        >
          <DropdownSelect
            data-cy="import-select"
            options={globalResourceTemplates}
            label={t('templates-heading')}
            aria-label={t('templates-heading')}
            value={{
              id: selectedGlobalResource,
              text: globalResourceTemplates.find((x) => x.id === selectedGlobalResource)?.text || '',
              value: selectedGlobalResource,
            }}
            onChange={(o) => setSelectedGlobalResource(o.id)}
          />
        </StandardModal>
      </ModalContext.Provider>
    </>
  );
};

export default Resources;
